<template>
	<div class="rmd-main">
		<h2 class="rmd-head" v-focus tabindex="0">雲端千眼推薦區</h2>
		<p class="sub-title">
			在書庫迷路的，有選擇困難症的人，就讓選書小編來幫你吧！
		</p>
		<div class="rmd-block" v-for="(r, key) in datas" :key="key">
			<div class="block-text" aria-hidden="true">
				<h3>{{ r.title }}:</h3>
				<br />
				{{ r.content }}
			</div>
			<div class="block-text sr-only">
				<h3>{{ r.title }}</h3>
				:
				{{ r.content }}
			</div>
			<img :src="r.src" class="block-img" :alt="r.title" />
			<div class="block-link">
				<a :href="r.link_url">{{ r.link_text }}</a>
			</div>
		</div>
		<div class="rmd-bottom">
			<button
				class="btn-bottom"
				@click="$router.push({ name: 'bookRepositoryIndex' })"
			>
				回書庫索引
			</button>
			<button class="btn-bottom" @click="$router.push({ name: 'home' })">
				回平台首頁
			</button>
		</div>
	</div>
</template>

<script>
import {
	recommendationSubjectRest,
	recommendationSubjectAction
} from "@/api/genericUser.js";

export default {
	data() {
		return {
			window,
			datas: []
		};
	},
	mounted() {
		this.load();
	},
	methods: {
		load() {
			recommendationSubjectRest.list().then(res => {
				this.datas = [];
				res.data.forEach(v => {
					this.datas.push({
						id: v.id,
						title: v.title,
						content: v.content,
						src: recommendationSubjectAction.getCover(v.id),
						link_url: v.link_url,
						link_text: v.link_text,
						order: v.order
					});
				});
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.rmd-main {
	.rmd-head {
		font-size: 30px;
		font-weight: bold;
	}
	.sub-title {
		color: darkred;
		font-size: 13px;
		margin-bottom: 50px;
	}
	.rmd-block {
		.block-text {
			background: black;
			color: white;
			padding: 10px;
			font-weight: bold;
		}
		.block-img {
			width: 60%;
			margin: 20px auto 5px 0;
			height: 280px;
			display: block;
		}
		.block-link {
			cursor: pointer;
			font-weight: bold;
			margin-bottom: 20px;
		}
	}
	.rmd-bottom {
		text-align: right;
		.btn-bottom {
			margin: 5px;
			font-size: 26px;
			padding: 5px 20px;
			background: darkred;
			color: white;
			border-radius: 10px;
		}
	}
}
h3 {
	margin: 0;
	padding: 0;
	font-size: 100%;
	font: inherit;
	color: inherit;
	display: contents;
}
a,
a:hover,
a:visited,
a:active {
	text-decoration: none;
	color: inherit;
}
</style>
